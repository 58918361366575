import GoogleTrends from "../../../../src/components/GoogleTrends";
import ReportViewer from "react-lighthouse-viewer";
import jsonReport from "../../../../content/blog/lumni/report-full.json";
import Collapsible from "react-collapsible";
import "../../../../content/blog/lumni/collapsible.css";
import * as React from 'react';
export default {
  GoogleTrends,
  ReportViewer,
  jsonReport,
  Collapsible,
  React
};